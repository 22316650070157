import { StaticImage } from "gatsby-plugin-image";
import { Seo } from "../../components/Seo";
import React from "react";
import ContactForm from "../../components/ContactForm";
import Button from "../../components/Button";

const AirwallexPage = () => {
  return (
    <main>
      <section className="relative overflow-hidden w-full h-full min-h-[800px] py-24 bg-[#f8f8f8]"
        style={{backgroundImage: 'url("")'}}>
        <div className="absolute block w-full top-0 left-0 h-full">
          <StaticImage src="../../images/airwallex_bg_one.png"
            alt=""
            className="block w-full h-full"
            placeholder="blurred" />
        </div>
        <div className="xl:container mx-auto px-4 relative">
          <div className="mx-auto mb-12">
            <StaticImage src="../../images/sourci_airwallex_logo_desktop.png"
              alt="Sourci + Airwallex Logo Desktop"
              className="mb-12 hidden lg:block max-w-[800px] mx-auto" />
            <StaticImage src="../../images/sourci_airwallex_logo_mobile.png"
              alt="Sourci + Airwallex Logo Mobile"
              className="mb-12 block lg:hidden max-w-[240px] mx-auto" />
          </div>
          <div className="bg-[#f8f8f8] py-16 px-16 grid lg:grid-cols-5 rounded-[5rem] shadow-md">
            <div className="text-black lg:col-span-2">
              <h1 className="text-4xl md:text-5xl font-black mb-8">Sourcing, Product Development, Manufacturing & Supply Chain</h1>
              <p className="text-black text-2xl mb-8">Australia's largest Sourcing company with teams in 8 countries.</p>
            </div>
            <div className="mt-12 lg:mt-0 lg:col-span-3">
              <ContactForm bg="light"
                location="Airwallex Landing Page"
                hsFormId="49fbd24b-cb72-4c09-9dba-aad61f8a70f2" />
            </div>
          </div>
        </div>
      </section>

      <section className="relative overflow-hidden w-full h-full min-h-[800px] py-24 mt-[-5rem] pt-20 flex items-center justify-center">
        <div className="absolute block w-full top-0 left-0 h-full">
          <StaticImage src="../../images/airwallex_gradient_bg.png"
            alt=""
            className="block w-full h-full"
            placeholder="blurred"
            formats={["auto","webp"]}
            quality="100" />
        </div>
        <div className="xl:container mx-auto px-8 relative mt-12 h-full">
          <div className="bg-sourci-teal rounded-full h-[270px] w-[270px] left-[calc(50%-490px)] top-[-70px] absolute z-50 p-8 hidden lg:block">
            <div className="text-xl font-bold text-white flex justify-center items-center w-full h-full text-center">Receive a $500 pre-paid Airwallex card when you engage Sourci on a package!*</div>
          </div>
          <div className="text-2xl font-bold text-white flex justify-center items-center w-full h-full text-center mb-8 lg:hidden">Receive a $500 pre-paid Airwallex card when you engage Sourci on a package!*</div>
          <div className="flex">
            <StaticImage src="../../images/SOURCI_AirWallexCard2.png"
              alt=""
              placeholder="blurred"
              formats={["auto", "webp"]}
              quality="100"
              className="max-w-[560px] mx-auto" />
          </div>
          <div>
            <p className="text-sm text-white text-center max-w-[450px] mx-auto">*Applies to any brand that engages with Sourci for a full price package. Not to be used in conjunction with any other offer</p>
          </div>
        </div>
      </section>

      <section className="py-24 bg-white">
        <div className="xl:container mx-auto px-8">
          <div className="mx-auto mb-12">
            <StaticImage src="../../images/who_trusts_us.png"
              alt="Sourci + Airwallex Logo Desktop"
              className="mb-12 hidden lg:block max-w-[400px] mx-auto" />
          </div>
          <div className="flex w-full flex-row flex-wrap my-8 justify-center">
            <div className="w-[calc(50%-2rem)] lg:w-[calc(25%-2rem)] items-center flex justify-center p-4">
              <StaticImage src="../../images/brands/cee_clear.png"
                alt=""
                placeholder="blurred" />
            </div>
            <div className="w-[calc(50%-2rem)] lg:w-[calc(25%-2rem)] items-center flex justify-center p-4">
              <StaticImage src="../../images/brands/vush.png"
                alt=""
                placeholder="blurred" />
            </div>
            <div className="w-[calc(50%-2rem)] lg:w-[calc(25%-2rem)] items-center flex justify-center p-4">
              <StaticImage src="../../images/brands/tanzee.png"
                alt=""
                placeholder="blurred" />
            </div>
            <div className="w-[calc(50%-2rem)] lg:w-[calc(25%-2rem)] items-center flex justify-center p-4">
              <StaticImage src="../../images/brands/pink_cosmetics.png"
                alt=""
                placeholder="blurred" />
            </div>
            <div className="w-[calc(50%-2rem)] lg:w-[calc(25%-2rem)] items-center flex justify-center p-4">
              <StaticImage src="../../images/brands/slickhair.png"
                alt=""
                placeholder="blurred" />
            </div>
            <div className="w-[calc(50%-2rem)] lg:w-[calc(25%-2rem)] items-center flex justify-center p-4">
              <StaticImage src="../../images/brands/cmc_gold.png"
                alt=""
                placeholder="blurred" />
            </div>
            <div className="w-[calc(50%-2rem)] lg:w-[calc(25%-2rem)] items-center flex justify-center p-4">
              <StaticImage src="../../images/brands/bach_eyewear.png"
                alt=""
                placeholder="blurred" />
            </div>
          </div>
          <div className="w-full text-center font-bold text-2xl">and heaps more.</div>
        </div>
      </section>

      <section className="relative overflow-hidden w-full h-full min-h-[800px] py-24 bg-[#f8f8f8]"
        style={{backgroundImage: 'url("")'}}>
        <div className="absolute block w-full top-0 left-0 h-full">
          <StaticImage src="../../images/airwallex_bg_two.png"
            alt=""
            className="block w-full h-full"
            placeholder="blurred" />
        </div>
        <div className="xl:container mx-auto px-8 text-center relative">
          <h3 className="font-black text-3xl lg:text-4xl mb-2">OUR WINNING FORMULA</h3>
          <div className="lg:max-w-[950px] mx-auto grid gap-x-12 gap-y-12 mb-12 mt-16 lg:grid-cols-2">
            <div className="px-4">
              <StaticImage src="../../images/55f86377-f1da-4abe-ad7a-610b9d0b23a7_Asset 1.png"
                alt=""
                className="mb-4" />
              <p className="font-black text-2xl mb-2">Industrial Design</p>
              <div className="text-lg">Our speciality. Helping you navigate the vast world of ideas, right down to finding the best materials and manufacturing methods.</div>
            </div>
            <div className="px-4">
              <StaticImage src="../../images/95d34a18-a0d2-4547-82fb-f2e8e54adb64_Asset 2.png"
                alt=""
                className="mb-4" />
              <p className="font-black text-2xl mb-2">Industrial Design</p>
              <div className="text-lg">A team with extensive cross-category experience to bring to life new designs and products for your brand.</div>
            </div>
            <div className="px-4">
              <StaticImage src="../../images/e040c3a3-88bc-4cf2-a425-1de2672a9a48_Asset 3.png"
                alt=""
                className="mb-4" />
              <p className="font-black text-2xl mb-2">Industrial Design</p>
              <div className="text-lg">Need to visualise your idea, or maybe you're ready to manufacture? We'll make sure you get what you need to pass that finish line.</div>
            </div>
            <div className="px-4">
              <StaticImage src="../../images/56d05b7c-1aab-4057-941d-1413af15156d_Asset 4.png"
                alt=""
                className="mb-4" />
              <p className="font-black text-2xl mb-2">Supply Chain Management</p>
              <div className="text-lg">Managing the entire sampling, manufacturing, quality assurance and packaging lifecycle including end-to-end freight and logistics management.</div>
            </div>
          </div>
          <Button type="button"
            to="/contact"
            className="transition-colors font-black text-2xl bg-sourci-teal hover:bg-sourci-teal-100 text-white"
            label="LET'S CHAT"
            padding="px-10 py-2" />
        </div>
      </section>

      <section className="relative overflow-hidden w-full h-full min-h-[800px] py-24 mt-[-5rem] pt-20 flex items-center justify-center">
        <div className="absolute block w-full top-0 left-0 h-full">
          <StaticImage src="../../images/airwallex_gradient_bg_two.png"
            alt=""
            className="block w-full h-full"
            placeholder="blurred"
            formats={["auto","webp"]}
            quality="100" />
        </div>
        <div className="xl:container mx-auto px-8 relative mt-12 h-full">
          <div className="grid lg:grid-cols-2 gap-x-16 gap-y-16">
            <div className="text-black">
              <h1 className="text-5xl md:text-8xl font-black text-white">The time is now.</h1>
            </div>
            <div className="mt-12 lg:mt-0">
              <ContactForm bg="light"
                location="Airwallex Landing Page"
                  hsFormId="49fbd24b-cb72-4c09-9dba-aad61f8a70f2" />
            </div>
          </div>
        </div>
      </section>

    </main>
  )
}

export default AirwallexPage;

export function Head() {
  return (
    <Seo title="Airwallex Offer | Sourci"
      description="Receive a $500 pre-paid Airwallex card when you engage Sourci on a package!*"
      canonical="/lp/bach-eyewear" />
  )
}